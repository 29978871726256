<template>
  <div>
    <Quotations
      v-if="showQ"
      :vendors="vendors"
      :reqItem="currentItem"
      :rights="rights"
      :getStaff="getStaff"
      :getProduct="getProduct"
      @close-comp="closeComp"
      @load-data="LoadData"
      @load-supp="LoadSupplier"
    />
    <!-- :groupItems="groupItems" -->

    <template v-else>
      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
        width="450px"
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Approve Requisition</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12" v-if="approveList.length == 0">
              <b-form-group
                label="Quantity"
                invalid-feedback="Quantity is required."
                ref="app_qty"
              >
                <b-form-input
                  type="number"
                  v-model="detailObj.approvedQty"
                  placeholder="Enter approved quantity"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Note"
                invalid-feedback="Note is required"
                ref="app_note"
              >
                <b-form-textarea
                  placeholder="Enter note"
                  v-model.trim="detailObj.approvalNote"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-table
              v-if="approveList.length > 0"
              :items="approveList"
              :fields="approveFields"
              small
              class="mt-1"
            >
              <template #cell(product)="data">
                {{ getProduct(data.item.productID) }}
              </template>
              <template #cell(qty)="data">
                <b-form-input
                  type="number"
                  v-model="data.item.approvedQty"
                  placeholder="Enter quantity"
                >
                </b-form-input>
              </template>
              <template #cell(status)="data">
                <div class="d-flex">
                  <b-button
                    pill
                    size="sm"
                    :variant="
                      data.item.status == 'pending' ? 'info' : 'outline-info'
                    "
                    class="mr-50"
                    v-b-tooltip.hover.top
                    title="Pending"
                    @click="data.item.status = 'pending'"
                  >
                    <span>P</span>
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    :variant="
                      data.item.status == 'approved'
                        ? 'success'
                        : 'outline-success'
                    "
                    class="mr-50"
                    v-b-tooltip.hover.top
                    title="Approved"
                    @click="data.item.status = 'approved'"
                  >
                    <span>A</span>
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    :variant="
                      data.item.status == 'rejected'
                        ? 'danger'
                        : 'outline-danger'
                    "
                    v-b-tooltip.hover.top
                    title="Rejected"
                    @click="data.item.status = 'rejected'"
                  >
                    <span>R</span>
                  </b-button>
                </div>
              </template>
            </b-table>

            <b-col md="12" class="mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                block
                @click="approveReq()"
                :disabled="request"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-else>Approve</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility2"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">{{ myObj.id == 0 ? "Add" : "Edit" }} Requisition</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility2 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Staff"
                invalid-feedback="Staff is required."
                ref="tID"
              >
                <v-select
                  v-model="myObj.tID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="staffList"
                  :reduce="(opt) => opt.id"
                  label="name"
                  :clearable="false"
                  placeholder="Choose staff"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                invalid-feedback="Product is required."
                ref="product"
              >
                <div class="d-flex">
                  <label class="bv-no-focus-ring col-form-label pt-0"
                    >Product</label
                  >
                  <feather-icon
                    class="cursor-pointer ml-50"
                    icon="PlusCircleIcon"
                    size="18"
                    v-b-tooltip.hover.top
                    title="Add Product"
                    @click="openP()"
                  />
                </div>
                <v-select
                  v-model="myObj.productID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="products"
                  :reduce="(val) => val.id"
                  label="name"
                  :clearable="false"
                  placeholder="Choose product"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Quantity"
                invalid-feedback="Quantity is required."
                ref="qty"
              >
                <b-form-input
                  type="number"
                  v-model="myObj.quantity"
                  placeholder="Enter quantity"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Note"
                invalid-feedback="Note is required"
                ref="note"
              >
                <b-form-textarea
                  placeholder="Enter note here"
                  v-model.trim="myObj.note"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col md="12" class="mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                block
                @click="saveReq()"
                :disabled="request"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-else>Save</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-inv"
        bg-variant="white"
        v-model="visibility3"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Add Product</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility3 = false"
          />
        </div>

        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12">
              <b-form-group
                invalid-feedback="Category is required."
                ref="catID"
              >
                <div class="d-flex">
                  <label class="bv-no-focus-ring col-form-label pt-0"
                    >Category</label
                  >
                  <feather-icon
                    class="cursor-pointer ml-50"
                    icon="PlusCircleIcon"
                    size="18"
                    v-b-tooltip.hover.top
                    title="Add Category"
                    @click="openCat()"
                  />
                </div>
                <v-select
                  v-model="invObj.categoryID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categories"
                  :reduce="(val) => val.id"
                  label="name"
                  :clearable="false"
                  placeholder="Select category"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Product Name"
                invalid-feedback="Product name is required."
              >
                <b-form-input
                  ref="pname"
                  v-model.trim="invObj.name"
                  placeholder="Enter product name here"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="12" class="mt-1">
              <b-button
                variant="success"
                @click="saveP()"
                :disabled="invSaving"
                block
              >
                <b-spinner v-if="invSaving" small type="grow" />
                <span v-else>Save</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-cat"
        bg-variant="white"
        v-model="visibility4"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Add Category</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility4 = false"
          />
        </div>

        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12">
              <b-form-group
                label="Category Name"
                invalid-feedback="Category name is required."
              >
                <b-form-input
                  ref="cname"
                  v-model.trim="catObj.name"
                  placeholder="Enter category name here"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="12" class="mt-1">
              <b-button
                variant="success"
                @click="saveCat()"
                :disabled="catSaving"
                block
              >
                <b-spinner v-if="catSaving" small type="grow" />
                <span v-else>Save</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-modal
        id="modal-reason"
        hide-footer
        centered
        title="State Your Reason"
        size="md"
      >
        <div>
          <b-form-group
            label="Rejection Reason"
            invalid-feedback="Rejection Reason is required"
            ref="reason"
          >
            <b-form-textarea
              placeholder="Enter your reason"
              v-model.trim="resObj.rejectionNote"
            ></b-form-textarea>
          </b-form-group>

          <b-row class="mt-1">
            <b-col md="12" class="text-right">
              <b-button
                variant="success"
                @click="rejectReq()"
                :disabled="savingReason"
              >
                <b-spinner v-if="savingReason" small type="grow" />
                <span v-else> Reject </span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-modal>

      <b-card>
        <b-row>
          <b-col xl="2" lg="3" md="4" class="mb-1 mb-lg-0" v-if="rights.add">
            <b-button
              @click="addOpen(0)"
              block
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Requisition</span>
            </b-button>
          </b-col>
          <b-col xl="10" lg="9" md="12">
            <b-row>
              <b-col class="mb-1 mb-md-0" md="3" sm="6">
                <b-button
                  block
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="
                    currentStatus == 'pending' ? 'primary' : 'outline-primary'
                  "
                  @click="filterData('pending')"
                >
                  <span class="align-middle">Pending</span>
                </b-button>
              </b-col>
              <b-col class="mb-1 mb-md-0" md="3" sm="6">
                <b-button
                  block
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="
                    currentStatus == 'approved' ? 'primary' : 'outline-primary'
                  "
                  @click="filterData('approved')"
                >
                  <span class="align-middle">Approved</span>
                </b-button>
              </b-col>
              <b-col class="mb-1 mb-md-0" md="3" sm="6">
                <b-button
                  block
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="
                    currentStatus == 'rejected' ? 'primary' : 'outline-primary'
                  "
                  @click="filterData('rejected')"
                >
                  <span class="align-middle">Rejected</span>
                </b-button>
              </b-col>
              <b-col class="mb-1 mb-md-0" md="3" sm="6">
                <b-button
                  block
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="
                    currentStatus == 'available' ? 'primary' : 'outline-primary'
                  "
                  @click="filterData('available')"
                >
                  <span class="align-middle">Quotation Available</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1" v-if="selectedIDs.length > 1">
          <b-col md="12">
            <b-button
              variant="outline-primary"
              class="btn-icon"
              @click="mergeReq()"
              :disabled="merging"
            >
              <b-spinner v-if="merging" small type="grow" />
              <span v-else>Merge</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          ref="reqTable"
          class="mt-2"
          :items="items"
          :fields="fields"
          :busy="dataLoading"
          small
          show-empty
          responsive
        >
          <!-- :current-page="currentPage"
          :per-page="perPage" -->
          <template #table-busy>
            <div class="d-flex justify-content-center my-2">
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>

          <template #cell(groupID)="data">
            <!-- <feather-icon
              v-if="data.item.groupID > 0"
              class="cursor-pointer mr-25"
              :icon="data.detailsShowing ? 'ArrowUpIcon' : 'ArrowDownIcon'"
              size="18"
              @click="test(data.item)"
            /> -->
            <b-form-checkbox
              v-if="data.item.status == 'pending' && !data.item.groupID"
              inline
              :checked="selectedIDs.includes(data.item.id)"
              @change="(e) => reqSelect(e, data.item.id)"
            >
              <b-badge variant="light-primary">
                {{ data.item.groupID }}
              </b-badge>
            </b-form-checkbox>
            <b-badge v-else variant="light-primary">
              {{ data.item.groupID }}
            </b-badge>
          </template>

          <template #row-details="row">
            <b-table
              :items="row.item.detailItems"
              :fields="fields"
              :busy="dataLoading"
              small
              show-empty
              responsive
              thead-class="d-none"
            >
              <template #cell(groupID)="dt">
                <b-badge variant="light-primary">
                  {{ dt.item.groupID }}
                </b-badge>
              </template>
              <template #cell(staff)="dt">
                <b-badge variant="light-primary">
                  {{ getStaff(dt.item.tID) }}
                </b-badge>
              </template>
              <template #cell(product)="dt">
                <b-badge variant="light-primary">
                  {{ getProduct(dt.item.productID) }}
                </b-badge>
              </template>
              <template #cell(date)="dt">
                <b-badge variant="light-primary">
                  {{
                    new Date(dt.item.date).toLocaleDateString("en-UK", {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    })
                  }}
                </b-badge>
              </template>
              <template #cell(status)="dt">
                <b-badge variant="light-primary">
                  {{ dt.value }}
                </b-badge>
              </template>
              <template #head(actions)="data">
                <div class="mr-5 text-right">
                  <span>{{ data.label }}</span>
                </div>
              </template>
            </b-table>
          </template>

          <template #cell(staff)="data">
            <b-badge variant="light-primary">
              {{ getStaff(data.item.tID) }}
            </b-badge>
          </template>

          <template #cell(product)="data">
            <b-badge variant="light-primary">
              {{ getProduct(data.item.productID) }}
            </b-badge>
          </template>

          <template #cell(date)="data">
            <b-badge variant="light-primary">
              {{
                new Date(data.item.date).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
            </b-badge>
          </template>

          <template #cell(status)="data">
            <b-badge variant="light-primary">
              {{ data.value }}
            </b-badge>
          </template>

          <template #head(actions)="data">
            <div class="mr-5 text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right d-flex">
              <b-button
                v-if="rights.add || rights.edit || rights.approve"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-50"
                v-b-tooltip.hover.left
                title="Add Quotation"
                @click="showComp(data.item)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
              <b-button
                v-if="
                  data.item.meta_count > 0 && parseInt(data.item.meta_staff) > 0
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                v-b-tooltip.hover.left
                title="Purchase Order"
                @click="print(data.item)"
              >
                <feather-icon icon="PrinterIcon" />
              </b-button>
              <template v-if="data.item.status == 'pending'">
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-50"
                  v-b-tooltip.hover.left
                  title="Edit"
                  @click="addOpen(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <template v-if="rights.approve && isAllow(data.item.productID)">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon mr-50"
                    v-b-tooltip.hover.bottomleft
                    title="Approve"
                    :disabled="reqLoading"
                    @click="openApprove(data.item)"
                  >
                    <b-spinner
                      v-if="reqLoading && currentReq == data.item.id"
                      small
                    />
                    <feather-icon v-else icon="CheckIcon" />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon mr-50"
                    v-b-tooltip.hover.bottomright
                    title="Reject"
                    @click="openReject(data.item)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </template>
              </template>
              <b-button
                v-if="rights.delete"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                class="btn-icon"
                v-b-tooltip.hover.bottomleft
                title="Delete"
                @click="DeleteReq(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <!-- <b-row class="">
          <b-col md="12" class="">
            <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              first-number
              last-number
              class="justify-content-center"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row> -->
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BSpinner,
  BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";
import Quotations from "./quotations.vue";

export default {
  components: {
    Quotations,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BSpinner,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
          approve: ch.approve,
        };
      });
    });

    this.$store.commit("setRights", right);
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // console.log(this.rights);
      this.LoadData();
      this.LoadProduct();
      this.LoadStaff();
      this.LoadSupplier();
      this.LoadCat();
    }
  },
  computed: {},
  data() {
    return {
      rights: {},
      categories: [],
      products: [],
      staffList: [],
      vendors: [],
      request: false,
      savingReason: false,
      buttonIcon: "PlusIcon",
      dataLoading: false,
      visibility: false,
      visibility2: false,
      logoloading: "",
      currentPage: 1,
      perPage: 30,
      fields: [
        { label: "groupID", key: "groupID" },
        { label: "staff", key: "staff" },
        { label: "date", key: "date" },
        { label: "product", key: "product" },
        { label: "quantity", key: "quantity" },
        { label: "note", key: "note" },
        { label: "status", key: "status" },
        { key: "actions", label: "actions" },
      ],
      items: [
        // {
        //   id: 1,
        //   status: "pending",
        //   rejectionNote: "",
        // },
        // {
        //   id: 2,
        //   status: "rejected",
        //   rejectionNote: "",
        // },
      ],
      allItems: [],
      myObj: {},
      resObj: {},
      currentStatus: "",
      detailObj: {},
      currentItem: {},
      groupItems: [],
      showQ: false,
      visibility3: false,
      invObj: {},
      invSaving: false,
      visibility4: false,
      catObj: {},
      catSaving: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      selectedIDs: [],
      merging: false,
      approveList: [],
      approveFields: [
        { label: "product", key: "product" },
        { label: "status", key: "status" },
        { label: "approve qty", key: "qty" },
      ],
      reqLoading: false,
      currentReq: 0,
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    test(row) {
      this.$set(row, "_showDetails", !row._showDetails);
      let details = this.items.filter(
        (el) => el.groupID == row.groupID && el.id != row.id
      );
      this.$set(row, "detailItems", details);
      // console.log(row);
    },
    print(item) {
      let qID = parseInt(item.meta_staff);

      let url =
        `https://${this.reportDomain}.myskool.app/Inventory/Order?dbb=` +
        this.$store.state.userData.db +
        "&cID=" +
        this.$store.state.userData.cId +
        "&reqID=" +
        item.id +
        "&userID=" +
        this.$store.state.userData.userID +
        "&qID=" +
        qID;

      window.open(url, "_blank");
    },
    filterData(st) {
      this.currentStatus = st;
      if (st == "available") {
        this.items = this.allItems.filter((el) => el.meta_count > 0);
      } else {
        this.items = this.allItems.filter((el) => el.status == st);
      }
    },
    getProduct(id) {
      let obj = this.products.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    getStaff(id) {
      let obj = this.staffList.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    isAllow(id) {
      let obj = this.products.find((el) => el.id == id);
      if (obj) {
        let cat = this.categories.find((el) => el.id == obj.categoryID);
        if (cat) {
          let ids = cat.allowedUsers
            ? cat.allowedUsers
                .split(",")
                .filter((el) => el)
                .map((el) => parseInt(el))
            : [];
          return ids.includes(this.$store.state.userData.userID);
        }
      }
      return false;
    },

    reqSelect(e, id) {
      // console.log(e, id);
      if (e) {
        this.selectedIDs.push(id);
      } else {
        this.selectedIDs = this.selectedIDs.filter((el) => el != id);
      }
      // console.log(this.selectedIDs);
    },
    async mergeReq() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do You want to merge the select requisitions?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Merge it!",
      });
      if (result.isConfirmed) {
        // console.log(this.selectedIDs);
        this.merging = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Requisition/Merge?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.selectedIDs,
          message: "Requisitions merged successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.merging = false;
        if (status) {
          this.selectedIDs = [];
          this.LoadData();
        }
      }
    },

    openP() {
      this.invObj = {
        id: 0,
        name: "",
        categoryID: 0,
        warehouseID: 0,
        supplierID: 0,
        quantity: 0,
        sellingPrice: 0,
        purchasePrice: 0,
        restockQuantity: 0,
        isHold: false,
        holdTill: new Date().toJSON(),
        image: "",
        sku: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility3 = true;
      var elem = this.$refs["catID"];
      elem.state = undefined;
      var elem = this.$refs["pname"];
      elem.state = undefined;
    },
    CheckCatID() {
      var elem = this.$refs["catID"];
      if (this.invObj.categoryID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPName() {
      var elem = this.$refs["pname"];
      if (this.invObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveP() {
      this.CheckCatID();
      this.CheckPName();
      if (this.CheckCatID() == true && this.CheckPName() == true) {
        this.invSaving = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "posStock?db=" +
            this.$store.state.userData.db,
          body: this.invObj,
          message: "Product added successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.invSaving = false;
        if (status) {
          this.LoadProduct();
          this.invObj = {
            id: 0,
            name: "",
            categoryID: 0,
            warehouseID: 0,
            supplierID: 0,
            quantity: 0,
            sellingPrice: 0,
            purchasePrice: 0,
            restockQuantity: 0,
            isHold: false,
            holdTill: new Date().toJSON(),
            image: "",
            sku: "",
            campusID: this.$store.state.userData.cId,
          };
          var elem = this.$refs["catID"];
          elem.state = undefined;
          var elem = this.$refs["pname"];
          elem.state = undefined;
        }
      }
    },
    openCat() {
      this.catObj = {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility4 = true;
      var elem = this.$refs["cname"];
      elem.state = undefined;
    },
    CheckCName() {
      var elem = this.$refs["cname"];
      if (this.catObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveCat() {
      if (this.CheckCName() == true) {
        this.catSaving = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "posCategories?db=" +
            this.$store.state.userData.db,
          body: this.catObj,
          message: "Category added successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.catSaving = false;
        if (status) {
          this.LoadCat();
          this.catObj = {
            id: 0,
            name: "",
            campusID: this.$store.state.userData.cId,
          };
          var elem = this.$refs["cname"];
          elem.state = undefined;
        }
      }
    },

    addOpen(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          productID: 0,
          quantity: 1,
          date: new Date().toJSON(),
          tID: 0,
          userID: this.$store.state.userData.userID,
          approvedQty: 0,
          approvedBy: 0,
          approvalNote: "",
          rejectedBy: 0,
          rejectionNote: "",
          status: "pending",
          note: "",
          campusID: this.$store.state.userData.cId,
          meta_staff: "",
          meta_count: 0,
        };
      } else {
        this.myObj = { ...id };
      }

      this.visibility2 = true;
      var elem = this.$refs["tID"];
      elem.state = undefined;
      var elem = this.$refs["product"];
      elem.state = undefined;
      var elem = this.$refs["qty"];
      elem.state = undefined;
    },
    CheckStaff() {
      var elem = this.$refs["tID"];
      if (this.myObj.tID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckProduct() {
      var elem = this.$refs["product"];
      if (this.myObj.productID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckQty() {
      var elem = this.$refs["qty"];
      let x = parseInt(this.myObj.quantity);
      if (isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        this.myObj.quantity = x;
        return (elem.state = true);
      }
    },
    async saveReq() {
      this.CheckStaff();
      this.CheckProduct();
      this.CheckQty();
      if (
        this.CheckStaff() == true &&
        this.CheckProduct() == true &&
        this.CheckQty() == true
      ) {
        this.request = true;

        // console.log(this.myObj);
        if (this.myObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "requisition?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Requisition added successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (status) {
            this.LoadData();
            this.visibility2 = false;
          }
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "requisition/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Requisition updated successfully!",
            context: this,
            body: this.myObj,
            token: this.$store.state.userData.token,
          });
          if (status) {
            this.LoadData();
            this.visibility2 = false;
          }
        }
        this.request = false;
      }
    },

    showComp(val) {
      this.currentItem = val;
      // if (val.groupID > 0) {
      //   this.groupItems = this.allItems.filter(
      //     (el) => el.groupID == val.groupID && el.id != val.id
      //   );
      // } else this.groupItems = [];
      this.showQ = true;
      // console.log(this.groupItems);
    },
    closeComp() {
      this.showQ = false;
      this.currentItem = {};
      // this.groupItems = [];
    },
    async openApprove(item) {
      // this.detailObj = { ...item };
      // this.visibility = true;
      this.reqLoading = true;
      this.currentReq = item.id;
      this.approveList = [];
      if (item.groupID > 0) {
        var obj = {
          url:
            this.$store.state.domain +
            "requisition/LoadGroupData?groupID=" +
            item.groupID +
            "&requisitionID=0&db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          token: this.$store.state.userData.token,
        };
        let res = await this.get(obj);
        if (Array.isArray(res) && res.length > 0) {
          this.approveList = res
            .filter((el) => el.status == "pending")
            .map((elem) => ({
              ...elem,
              approvedQty: elem.quantity,
              status: "approved",
            }));

          this.detailObj = {
            approvalNote: "",
          };
          this.visibility = true;
        }
      } else {
        var obj = {
          url:
            this.$store.state.domain +
            "requisition/LoadGroupData?groupID=0&requisitionID=" +
            item.id +
            "&db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          token: this.$store.state.userData.token,
        };
        let res = await this.get(obj);
        if (Array.isArray(res) && res.length > 0) {
          this.detailObj = res[0];

          this.visibility = true;
          var elem = this.$refs["app_qty"];
          elem.state = undefined;
        }
      }
      this.reqLoading = false;
      this.currentReq = 0;
      // console.log(this.approveList, this.detailObj);
    },
    CheckQuantity() {
      var elem = this.$refs["app_qty"];
      let x = parseInt(this.detailObj.approvedQty);
      if (isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        this.detailObj.approvedQty = x;
        return (elem.state = true);
      }
    },
    CheckValues() {
      let state = true;
      let same = this.approveList.every(
        (el) => el.status == "rejected" || el.status == "pending"
      );
      if (same) state = false;
      else {
        this.approveList.forEach((el) => {
          if (el.status == "approved") {
            let x = parseInt(el.approvedQty);
            if (isNaN(x) || x <= 0) state = false;
            else {
              el.approvedQty = x;
              el.approvalNote = this.detailObj.approvalNote;
              el.approvedBy = this.$store.state.userData.userID;
              el.rejectedBy = 0;
            }
          } else {
            el.approvedQty = 0;
            el.approvalNote = "";
            el.approvedBy = 0;
            el.rejectedBy =
              el.status == "rejected" ? this.$store.state.userData.userID : 0;
          }
        });
      }
      return state;
    },
    async approveReq() {
      if (this.approveList.length > 0) {
        if (this.CheckValues() == false) {
          this.$bvToast.toast("Please enter the details correctly.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          // console.log(this.approveList);
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Requisition/approveGroup?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: this.approveList,
            message: "Requisitions approved successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadData();
            this.visibility = false;
          }
        }
      } else if (this.CheckQuantity() == true) {
        this.request = true;
        this.detailObj.approvedBy = this.$store.state.userData.userID;
        this.detailObj.status = "approved";
        // console.log(this.detailObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Requisition/approveGroup?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: [this.detailObj],
          message: "Requisition approved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadData();
          this.visibility = false;
        }
        // var status = await this.put({
        //   url:
        //     this.$store.state.domain +
        //     "requisition/" +
        //     this.detailObj.id +
        //     "?db=" +
        //     this.$store.state.userData.db,
        //   message: "Requisition approved successfully!",
        //   context: this,
        //   body: this.detailObj,
        //   token: this.$store.state.userData.token,
        // });
      }
    },
    openReject(item) {
      this.resObj = { ...item };
      this.$bvModal.show("modal-reason");
    },
    CheckReason() {
      var elem = this.$refs["reason"];
      if (this.resObj.rejectionNote == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async rejectReq() {
      if (this.CheckReason() == true) {
        this.savingReason = true;
        this.resObj.rejectedBy = this.$store.state.userData.userID;
        this.resObj.status = "rejected";

        var status = await this.put({
          url:
            this.$store.state.domain +
            "requisition/" +
            this.resObj.id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Requisition rejected successfully!",
          context: this,
          body: this.resObj,
          token: this.$store.state.userData.token,
        });
        this.savingReason = false;
        if (status) {
          this.LoadData();
          this.$bvModal.hide("modal-reason");
        }
      }
    },
    async DeleteReq(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "requisition/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Requisition removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "requisition/LoadData?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      let sorted = res.sort((a, b) => a.groupID - b.groupID);
      this.allItems = sorted;

      if (this.allItems.length > 0 && this.currentStatus != "") {
        this.filterData(this.currentStatus);
      } else this.items = sorted;

      this.dataLoading = false;

      // console.log(this.items);
    },
    async LoadProduct() {
      var obj = {
        url:
          this.$store.state.domain +
          "posStock?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.products = await this.get(obj);
      // console.log(this.products);
    },
    async LoadCat() {
      var obj = {
        url:
          this.$store.state.domain +
          "posCategories?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.categories = await this.get(obj);
    },
    async LoadStaff() {
      var obj = {
        url:
          this.$store.state.domain +
          "staff/filter?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=0&status=present",
        token: this.$store.state.userData.token,
      };
      this.staffList = await this.get(obj);
    },
    async LoadSupplier() {
      var obj = {
        url:
          this.$store.state.domain +
          "posSupplier?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.vendors = await this.get(obj);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
